import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "school-list" }
const _hoisted_2 = { class: "school-list__block" }
const _hoisted_3 = { class: "school-list__wrap" }
const _hoisted_4 = { class: "school-list__search-wrap-mobile-container" }
const _hoisted_5 = { class: "school-list__search-wrap" }
const _hoisted_6 = { class: "main-table__mobile-wrap" }
const _hoisted_7 = { class: "main-table__head" }
const _hoisted_8 = { class: "main-table__head-big-tr" }
const _hoisted_9 = { class: "main-table__sort-block" }
const _hoisted_10 = { class: "main-table__head-big-tr" }
const _hoisted_11 = { class: "main-table__sort-block" }
const _hoisted_12 = { class: "main-table__head-big-tr" }
const _hoisted_13 = { class: "main-table__sort-block" }
const _hoisted_14 = { class: "main-table__body-id" }
const _hoisted_15 = { class: "main-table__body-big-tr" }
const _hoisted_16 = { class: "main-table__body-big-tr" }
const _hoisted_17 = { class: "main-table__body-little-tr" }
const _hoisted_18 = {
  key: 0,
  class: "main-table__body-school-dot"
}
const _hoisted_19 = { class: "main-table__svg-block" }
const _hoisted_20 = { class: "school-list__popup-buttons" }
const _hoisted_21 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuperAdminPanelNavMenu = _resolveComponent("SuperAdminPanelNavMenu")!
  const _component_Pluse = _resolveComponent("Pluse")!
  const _component_Search = _resolveComponent("Search")!
  const _component_SortArrow = _resolveComponent("SortArrow")!
  const _component_View = _resolveComponent("View")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SuperAdminPanelNavMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "school-list__create-new",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (this.createSchool()))
        }, [
          _createVNode(_component_Pluse),
          _cache[10] || (_cache[10] = _createTextVNode(" Create school admin "))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "Search by name or email",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchData) = $event))
          }, null, 512), [
            [_vModelText, _ctx.searchData]
          ]),
          _createVNode(_component_Search)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "main-table__head-id" }, " ID ", -1)),
          _createElementVNode("div", _hoisted_8, [
            _cache[11] || (_cache[11] = _createTextVNode(" Name ")),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_SortArrow, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (this.getAdminsList('?search=', 'full_name')))
              }),
              _createVNode(_component_SortArrow, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (this.getAdminsList('?search=', '-full_name'))),
                class: "main-table__sort-bottom"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[12] || (_cache[12] = _createTextVNode(" Email ")),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_SortArrow, {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (this.getAdminsList('?search=', 'email')))
              }),
              _createVNode(_component_SortArrow, {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (this.getAdminsList('?search=', '-email'))),
                class: "main-table__sort-bottom"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[13] || (_cache[13] = _createTextVNode(" School Name ")),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_SortArrow, {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (this.getAdminsList('?search=', 'school_name')))
              }),
              _createVNode(_component_SortArrow, {
                onClick: _cache[7] || (_cache[7] = ($event: any) => (this.getAdminsList('?search=', '-school_name'))),
                class: "main-table__sort-bottom"
              })
            ])
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.adminsData, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "main-table__body",
            key: item
          }, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(item.id), 1),
            _createElementVNode("div", _hoisted_15, _toDisplayString(item?.full_name), 1),
            _createElementVNode("div", _hoisted_16, _toDisplayString(item?.email), 1),
            _createElementVNode("div", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.schools, (school, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: school.id,
                  class: "main-table__body-school"
                }, [
                  _createElementVNode("div", null, _toDisplayString(school?.title), 1),
                  (item.schools.length - 1 !== i)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, ","))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_View, {
                class: "main-table__svg",
                onClick: ($event: any) => (_ctx.viewSchool(item.id))
              }, null, 8, ["onClick"]),
              _createVNode(_component_Edit, {
                class: "main-table__svg",
                onClick: ($event: any) => (_ctx.editSchool(item.id))
              }, null, 8, ["onClick"]),
              _createVNode(_component_Delete, {
                class: "main-table__svg",
                onClick: ($event: any) => (_ctx.openDeletePoup(item.id))
              }, null, 8, ["onClick"])
            ])
          ]))
        }), 128))
      ]),
      _createVNode(_component_Pagination, {
        propertyMeta: _ctx.adminsMeta,
        propertyList: _ctx.adminsData,
        page: _ctx.page,
        handlePage: _ctx.handlePage
      }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"])
    ]),
    (_ctx.isModalDelete)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModalDelete
        }, {
          default: _withCtx(() => [
            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "school-list__popup-title" }, " Are you sure? ", -1)),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", {
                class: "school-list__popup-button-yes",
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.deleteAdmin && _ctx.deleteAdmin(...args)))
              }, " Yes "),
              _createElementVNode("div", {
                class: "school-list__popup-button-no",
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args)))
              }, " No "),
              (this.errorAdmin)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createVNode(_component_AlertBlock, {
                      message: this.errorAdmin
                    }, null, 8, ["message"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}